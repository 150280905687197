body {
    margin: 0;
    font-size: 11px;
    line-height: 1;
    letter-spacing: 0.5px;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h1 a h2,
h2 a {
    font-size: 11px;
    line-height: 1.5;
    width: 100%;
}

p,
p a {
    font-size: 11px;
    line-height: 1.5;
    margin: 4px 0;
    text-transform: capitalize;
}

video::-webkit-media-controls {
    display: none;
}

video {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    border-radius: 12px;
}

img {
    width: 100%;
    height: 100%;
}

[class^="number-slide"],
[class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 300px;
    max-height: 100vh;
}

.number-slide1 {
    background: rgb(64, 175, 255);
    background: linear-gradient(128deg,
            rgba(64, 175, 255, 1) 0%,
            rgba(63, 97, 255, 1) 100%);
}

.number-slide2 {
    background: rgb(255, 75, 64);
    background: linear-gradient(128deg,
            rgba(255, 154, 63, 1) 0%,
            rgba(255, 75, 64, 1) 100%);
}

.number-slide3 {
    background: rgb(182, 255, 64);
    background: linear-gradient(128deg,
            rgba(182, 255, 64, 1) 0%,
            rgba(63, 255, 71, 1) 100%);
    background: linear-gradient(128deg,
            rgba(189, 255, 83, 1) 0%,
            rgba(43, 250, 82, 1) 100%);
}

.number-slide4 {
    background: rgb(64, 255, 242);
    background: linear-gradient(128deg,
            rgba(64, 255, 242, 1) 0%,
            rgba(63, 188, 255, 1) 100%);
}

.number-slide5 {
    background: rgb(255, 64, 156);
    background: linear-gradient(128deg,
            rgba(255, 64, 156, 1) 0%,
            rgba(255, 63, 63, 1) 100%);
}

.number-slide6 {
    background: rgb(64, 76, 255);
    background: linear-gradient(128deg,
            rgba(64, 76, 255, 1) 0%,
            rgba(174, 63, 255, 1) 100%);
}



@media only screen and (min-width : 280px) {
    h3 {
        font-size: 2rem;
        line-height: 1.5;
    }
}

@media only screen and (min-width : 768px) {
    h3 {
        font-size: 2rem;
        line-height: 1.5;
    }
}

@media only screen and (min-width : 1000px) {
    h3 {
        font-size: 3rem;
        line-height: 1.5;
    }
}

@media only screen and (min-width : 1600px) {
    h3 {
        font-size: 3rem;
        line-height: 1.5;
    }
}
